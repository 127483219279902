import {FC, ReactNode, useEffect, useState} from 'react';
import {useStore} from '@nanostores/react';
import {ChevronLeftIcon, ChevronRightIcon, Squares2X2Icon, QuestionMarkCircleIcon, UserCircleIcon} from '@heroicons/react/24/outline';
import {$appSecrets, isMenuOpen as isAppUIMenuOpen} from '@stores/AppUIStore';
import {$authSession, $authUserPermissions} from '@stores/AuthStore';
import usePermissions from '@hooks/usePermissions';
import useSessionValidity from '@hooks/useSessionValidity';
import {hasPermission, userLogin, userLogout} from '@helpers/auth';
import {ACCOUNT_REGISTRATION_MENU, MenuPrefixes, PRIVATE_MENU_GROUPS, PUBLIC_MENU_GROUPS} from '@constants/Menu';
import {UserPermissions} from '@constants/Common';
import {PrivateRoutes} from '@constants/Route';
import SideBarItem from '@components/SidebarItem';

export interface IMenuGroup {
  title?: string;
  menus: IMenuItem[];
}

export interface ISubMenuItem {
  subMenuTitle: string;
  url: string;
}

export interface IMenuItem {
  label: string;
  url: string;
  prefix?: string;
  icon?: ReactNode;
  subMenus?: ISubMenuItem[];
}

interface ISideBarProps {
  currentUrl: string | null;
  subMenuTitle?: string;
}

const SideBar: FC<ISideBarProps> = ({currentUrl}) => {
  const currentAuthSession = useStore($authSession);
  const appSecrets = useStore($appSecrets);

  const {fetchPermissions, userPermissions} = usePermissions();
  const {checkIsTokenExpired} = useSessionValidity();

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [menuGroups, setMenuGroups] = useState<IMenuGroup[]>([]);

  const setSessionPageLoadCount = () => {
    const sessionPageLoadCount = localStorage.getItem('sessionPageLoadCount');
    const newCount = sessionPageLoadCount ? parseInt(sessionPageLoadCount) + 1 : 1;
    localStorage.setItem('sessionPageLoadCount', newCount.toString());
  };

  const getSessionValidity = async () => {
    const isTokenExpired = await checkIsTokenExpired();

    if (isTokenExpired) {
      // Signs out user if access token is expired
      userLogout();
    } else {
      setSessionPageLoadCount();
    }
  };

  const redirectToHome = () => {
    window.location.href = '/';
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogin = async () => {
    userLogin();
  };

  const handleLogout = () => {
    userLogout();
  };

  const handleVerifyAccountRegistration = () => {
    const isAccountRegistered = hasPermission(UserPermissions.PostPaidBilling);
    if (!isAccountRegistered) {
      const newMenuGroups = [...menuGroups];
      newMenuGroups[0].menus.push(ACCOUNT_REGISTRATION_MENU);
      setMenuGroups(newMenuGroups);

      const sessionPageLoadCount = localStorage.getItem('sessionPageLoadCount');
      const isFirstSignin = parseInt(sessionPageLoadCount ?? '') === 1;
      if (isFirstSignin && !window.location.pathname.includes(PrivateRoutes.AccountRegistration)) {
        window.location.href = PrivateRoutes.AccountRegistration;
      }
    }
  };

  const handleSetMenuGroups = () => {
    const hiddenRoutes: PrivateRoutes[] = [];
    if (appSecrets.featureSwitchAbn === 'OFF') {
      hiddenRoutes.push(PrivateRoutes.ABN);
    }
    if (appSecrets.featureSwitchCompany === 'OFF') {
      hiddenRoutes.push(PrivateRoutes.Company);
    }

    const currentMenus = currentAuthSession ? PRIVATE_MENU_GROUPS : PUBLIC_MENU_GROUPS;
    const documentMenuIndex = currentMenus.findIndex(menu => menu.title === 'Order a Document');
    const documentMenus = currentMenus[documentMenuIndex].menus.filter(menu => !hiddenRoutes.includes(menu.url as PrivateRoutes));
    currentMenus[documentMenuIndex] = {...currentMenus[documentMenuIndex], menus: documentMenus};

    setMenuGroups(currentMenus);
  };

  useEffect(() => {
    function SetMenuOptionsByPageSize() {
      if (window.innerWidth <= 834) {
        setIsMenuOpen(false);
      } else {
        setIsMenuOpen(true);
      }
    }
    SetMenuOptionsByPageSize();
    window.addEventListener('resize', SetMenuOptionsByPageSize);

    return () => {
      window.removeEventListener('resize', SetMenuOptionsByPageSize);
    };
  }, []);

  useEffect(() => {
    // Update application UI state for menu
    isAppUIMenuOpen.set(isMenuOpen);
  }, [isMenuOpen]);

  useEffect(() => {
    if (currentAuthSession) {
      getSessionValidity();
      fetchPermissions();
    }
    handleSetMenuGroups();
  }, [currentAuthSession, appSecrets]);

  useEffect(() => {
    if (userPermissions) {
      $authUserPermissions.set(userPermissions);
      handleVerifyAccountRegistration();
    }
  }, [userPermissions]);

  return (
    <aside
      data-test-id={`sidebar-asid`}
      className={`bg-primecc-navy fixed flex flex-col justify-between h-full transition-all duration-100 ease-linear ${
        isMenuOpen ? 'w-full md:w-[264px]' : 'w-[60px]'
      }`}>
      <div>
        <div className="flex h-16 p-5">
          <div className={`items-center flex-1 ${isMenuOpen ? 'flex' : 'hidden'}`}>
            <div data-test-id={`sidebar-application-name`} className="text-xl uppercase text-white hover:cursor-pointer" onClick={redirectToHome}>
              <img src="/img/prime_document_logo.png" alt="Prime Documents Logo" />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button data-test-id={`sidebar-menu-button`} className="p-1 rounded-full bg-white" onClick={toggleMenu}>
              {isMenuOpen ? (
                <ChevronLeftIcon className="w-3 h-3 text-black font-bold" strokeWidth={3.2} />
              ) : (
                <ChevronRightIcon className="w-3 h-3 text-black font-bold" strokeWidth={3.2} />
              )}
            </button>
          </div>
        </div>
        {menuGroups.map((group, groupIndex) => (
          <div key={`sidebar-menu-group-${groupIndex}`} className="mb-3">
            {isMenuOpen && group.title && (
              <div className="py-1 pl-3 pr-2">
                <h1 className="text-xs leading-5 font-semibold text-[#9AA4B2]">{group.title}</h1>
              </div>
            )}
            <div className="overflow-y-auto overflow-x-hidden h-full max-h-96">
              {group.menus.map((menuItem, menuItemIndex) => (
                <SideBarItem
                  key={menuItemIndex.toString()}
                  isMainMenuOpen={isMenuOpen}
                  id={menuItemIndex.toString()}
                  currentUrl={currentUrl}
                  menu={menuItem}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="py-4">
        <SideBarItem
          isMainMenuOpen={isMenuOpen}
          id="prime-support-menu"
          currentUrl={currentUrl}
          menu={{
            url: PrivateRoutes.Support,
            prefix: MenuPrefixes.Support,
            label: 'Support',
            icon: <QuestionMarkCircleIcon className="w-6 h-6" />,
          }}
        />
        <div className="border border-t border-[#364152] my-4 mx-2" />
        {!!currentAuthSession ? (
          <SideBarItem
            isMainMenuOpen={isMenuOpen}
            id="prime-sigout-menu"
            currentUrl={currentUrl}
            menu={{
              url: '',
              label: 'Sign Out',
              icon: <img src={currentAuthSession.user?.image as string} alt="Auth User Image" height={25} width={25} className="rounded-full" />,
            }}
            isButton
            onClick={handleLogout}
          />
        ) : (
          <SideBarItem
            isMainMenuOpen={isMenuOpen}
            id="prime-signin-menu"
            currentUrl={currentUrl}
            menu={{
              url: '',
              label: 'Sign In',
              icon: <UserCircleIcon className="w-6 h-6" />,
            }}
            isButton
            onClick={handleLogin}
          />
        )}
      </div>
    </aside>
  );
};

export default SideBar;
