import usePrimeDocsAPI from './usePrimeDocsAPI';
import useSWRMutation from 'swr/mutation';

const useSessionValidity = () => {
  const {userInfoClient} = usePrimeDocsAPI();

  const getIsTokenExpired = async () => {
    let isTokenExpired: boolean = false;

    try {
      isTokenExpired = await userInfoClient.userInfo_IsTokenExpired();
    } catch (e: any) {
      console.error(`[useSessionValidity.getIsTokenExpired]: Error occured. Error was - `, (e as Error).stack);

      if (e.status === 401) {
        return (isTokenExpired = true);
      }
    }

    return isTokenExpired;
  };

  const {
    trigger: checkIsTokenExpired,
    isMutating: isCheckingIsTokenExpired,
    data: isTokenExpired,
  } = useSWRMutation('/UserInfo/IsTokenExpired', getIsTokenExpired);

  return {
    checkIsTokenExpired,
    isTokenExpired,
    isCheckingIsTokenExpired,
  };
};

export default useSessionValidity;
